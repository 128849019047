import React, { useRef, useEffect, useState, useContext } from "react";
import { AgGridReact } from "ag-grid-react";
import { useParams } from "react-router-dom";

import classes from "./profession_table.module.css";
import { getProfessionList } from "../../../../services/professionServices";
import VendorSearch from "../../../vendor-search/VendorSearch";
import Pagination from "../../component/pagination";
import ActionButton from "../../component/ActionButton";
import { verifyType } from "../../../../services/utilityApiServices";
import Approval from "../../component/approval/Approval";
import { Context } from "../../../context";

function ProfessionTable() {
  const { id } = useParams();

  const number = useRef(0);
  const profileInfo = useContext(Context);

  const [apiResponse, setApiResponse] = useState(null);

  const [pageNumber, setPageNumber] = useState(1);
  const [status, setStatus] = useState("All");
  const [search, setSearch] = useState("");
  const [column, setColumn] = useState([
    {
      headerName: "ID",
      cellRenderer: (props) => {
        return <>{props.data.id}</>;
      },
    },
    {
      field: "name",
      headerName: "Profession Name",
    },
    {
      field: "contact",
      headerName: "Contact Details",
    },
    {
      field: "category.name",
      headerName: "Profession Name",
      sortable: true,
    },

    { field: "city", headerName: "City" },
    {
      field: "Verification Type",
      cellRenderer: ({ data }) => {
        return (
          <select
            value={data?.verification_type}
            onChange={(e) => handleVerifyType(e.target.value, data)}
          >
            <option value="none">None </option>
            <option value="premium">Premium </option>
            <option value="gold">Gold </option>
            <option value="silver">Silver </option>
            <option value="bronze">Bronze </option>
          </select>
        );
      },
    },
    {
      field: "is_approved",
      headerName: "Approved",
      cellRenderer: (props) => (
        <Approval isApproved={props.value} cellData={props} />
      ),
    },
    {
      field: "Action",
      cellRenderer: (props) => {
        return <ActionButton cellData={props} />;
      },
    },
  ]);

  useEffect(() => {
    if (profileInfo.state.profile.user_type != "admin") {
      setColumn((prev) => prev.filter((el) => el.field != "Verification Type"));
    }
  }, []);

  useEffect(() => {
    getProfessionList(id, pageNumber, status, search)
      .then((res) => setApiResponse(res.data))
      .catch((err) => console.log(err));

    return () => {
      setApiResponse(null);
    };
  }, [pageNumber, status, search]);

  const defaultColDef = {
    resizable: false,
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: "left" },
  };

  function getPageNumber(url) {
    let num = url?.split("page=")[1];
    num = num == undefined ? 1 : num;
    number.current = (parseInt(num) - 1) * 10;
    setPageNumber(num);
  }

  function filter(value) {
    if (value === "is_deleted") {
      setColumn((prev) => prev.filter((element) => element.field != "Action"));
    } else if (!column.some((el) => el.field == "Action")) {
      setColumn((prev) => [
        ...prev,
        {
          field: "Action",
          cellRenderer: (props) => {
            return <ActionButton cellData={props} />;
          },
        },
      ]);
    }
    setStatus(value);
    setPageNumber(1);
    number.current = 0;
  }

  function searchText(text) {
    setSearch(text);
    setPageNumber(1);
    number.current = 0;
  }

  function handleVerifyType(value, data) {
    const { id, category } = data;
    const payload = {
      category_type: category?.category_type,
      verification_type: value,
    };

    verifyType(id, payload)
      .then((res) => {})
      .catch((err) => console.log(err));
  }

  return (
    <>
      <VendorSearch filter={filter} status={status} searchText={searchText} />
      <div className={`ag-theme-alpine ${classes.table}`}>
        <>
          <AgGridReact
            rowData={apiResponse?.results}
            columnDefs={column}
            defaultColDef={defaultColDef}
            animateRows={true}
          />
        </>
      </div>
      <Pagination
        data={apiResponse}
        getPageNumber={getPageNumber}
        currentPage={number.current}
      />
    </>
  );
}

export default ProfessionTable;
