import React, { useEffect, useRef, useState } from "react";
import classes from "./input_tags.module.css";
import { getTagsLists } from "../../../../services/utilityApiServices";

function InputTags({ type, tags, setTags,isSubmitted }) {
  const [filteredLists, setFilteredLists] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const ref = useRef(null);

  useEffect(() => {
    getTagsLists(type)
      .then((res) => setTagsList(res.data))
      .catch((err) => console.log(err));

    return () => {
      setTagsList([]);
    };
  }, []);

  function filterTags(key) {
    if ("" == key) {
      setFilteredLists([]);
    } else {
      let filteredTag = tagsList.filter((el) =>
        el.name.toLowerCase().includes(key.toLowerCase())
      );
      setFilteredLists(filteredTag);
    }
  }

  function handleSelectedFilter(name) {
    if (tags.includes(name)) {
      let temp = tags.filter((el) => el != name);
      setTags(temp);
    } else {
      setTags((prev) => [...prev, name]);
    }
    setFilteredLists([]);
    ref.current.value = null;
  }

  return (
    <div className={`col-md-12 ${classes.input_container}`}>
      <label htmlFor="tag_input" className="col-md-12 text-start">
        Tag*
      </label>
      <textarea
      style={{
        overflow:'hidden'
      }}
        ref={ref}
        id="tag_input"
        placeholder="Enter tags"
        className="col-md-12"
        onChange={(e) => filterTags(e.target.value)}
        onKeyUp={(e) => {
          if (e.key == "Enter") {
            handleSelectedFilter(e.target.value);
            e.target.value = null;
          }
        }}
      />
      <div className={`${classes.tag_input}`}>
        {filteredLists.map((item, index) => (
          <div onClick={() => handleSelectedFilter(item.name)} key={index}>
            {item?.name}{" "}
          </div>
        ))}
      </div>
      <div className={`${classes.tags_pill}`}>
        {tags.map((item, index) => (
          <div
            className={`${classes.pill}`}
            key={index}
            onClick={() => handleSelectedFilter(item)}
          >
            <p>{item}</p>
            <span className={`text-white`}>
              <svg
                fill="none"
                stroke="white"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </span>
          </div>
        ))}
      </div>

      <span className="text-danger">
        {isSubmitted && tags.length <= 0 && "Tag  is Required"}
      </span>

    </div>
  );
}

export default InputTags;
