import { AgGridReact } from "ag-grid-react";
import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";

import { Link } from "react-router-dom";
import { Context } from "../../../context";
import Toggle from "react-toggle";

import ActionButton from "../../component/ActionButton";
import { vendorList } from "../../../../services/stores";
import { vendorActive } from "../../../../services/utilityApiServices";
import { CategoryTypeEnum } from "../../../../utility/utilityFunction";
import VendorSearch from "../../../vendor-search/VendorSearch";
import classes from "./vendor-table.module.css";
import Pagination from "../../component/pagination";
import Approval from "../../component/approval/Approval";

function VendorTable() {
  const { dispatch } = useContext(Context);

  const [data, setData] = useState();

  const [pageNumber, setPageNumber] = useState(1);
  const [status, setStatus] = useState("All");
  const [search, setSearch] = useState("");

  const number = useRef(0);

  useEffect(() => {
    vendorList(pageNumber, status, search)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err.response));
  }, [pageNumber, status, search]);

  const handleToggleChange = useCallback((value, props) => {
    let payload = {
      category_type: props.data?.category?.category_type,
      category_id: props.data.id,
      active: value,
    };
    vendorActive(payload)
      .then((res) => {})
      .catch((err) => console.log(err));
  }, []);

  function changeVendor(props) {
    const { data } = props;

    localStorage.setItem("admin_vendor_id", data.vendor__id);
    localStorage.setItem("admin_vendor_name", data.vendor__first_name);

    dispatch({
      type: "user_type",
      value: {
        vendorId: data.vendor__id,
        vendorName: data.name,
      },
    });
  }

  const columnDefs = useRef([
    {
      headerName: "ID",
      cellRenderer: (props) => {
        return <>{props.data.id}</>;
      },
    },
    {
      field: "vendor__first_name",
      headerName: "Vendor Name",
      sortable: true,
      cellRenderer: (props) => {
        return (
          <Link
            onClick={() => changeVendor(props)}
            to={`/vendor/store/${props.data.vendor__id}`}
            replace={true}
            className={`${classes.img_link}`}
          >
            <img
              src="https://www.niti.gov.in/sites/default/files/2023-03/placeholder_2.jpg"
              className={`${classes.img}`}
              alt="placeholder"
            />
            {props.value}
          </Link>
        );
      },
    },
    {
      field: "vendor__phone",
      headerName: "Contact Details",
    },
    {
      field: "type",
      headerName: "Category",
      cellRenderer: (props) => {
        return CategoryTypeEnum[props.data?.category?.category_type];
      },
    },
    {
      field: "city",
      headerName: "City",
    },

    {
      field: "is_approved",
      headerName: "Approved",
      cellRenderer: (props) => (
        <Approval isApproved={props.value} cellData={props} />
      ),
    },

    {
      field: "is_active",
      headerName: "Status",
      cellRenderer: (props) => (
        <Toggle
          defaultChecked={props.value}
          icons={false}
          onChange={(e) => handleToggleChange(e.target.checked, props)}
        />
      ),
    },

    {
      field: "Action",
      cellRenderer: (props) => {
        return <ActionButton cellData={props} />;
      },
    },
  ]).current;

  const defaultColDef = useMemo(() => ({
    resizable: false,
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: "left" },
  }));

  function getPageNumber(url) {
    let num = url?.split("page=")[1];
    num = num == undefined ? 1 : num;
    number.current = (parseInt(num) - 1) * 10;
    setPageNumber(num);
  }

  function filter(value) {
    if (value === "is_deleted") {
      columnDefs.pop();
    } else if (
      value !== "is_deleted" &&
      columnDefs[columnDefs.length - 1].field !== "Action"
    ) {
      columnDefs.push({
        field: "Action",
        cellRenderer: (props) => {
          return <ActionButton cellData={props} />;
        },
      });
    }
    setStatus(value);
    setPageNumber(1);
    number.current = 0;
  }

  function searchText(text) {
    setSearch(text);
    setPageNumber(1);
    number.current = 0;
  }

  return (
    <>
      <VendorSearch filter={filter} status={status} searchText={searchText} />

      <div className={`ag-theme-alpine ${classes.table}`}>
        <AgGridReact
          rowData={data?.results}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
        />
      </div>
      <Pagination
        data={data}
        getPageNumber={getPageNumber}
        currentPage={number.current}
      />
    </>
  );
}

export default VendorTable;
