import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useReducer } from "react";
import { Context, initialState, reducer } from "./components/context";
import Navigation from "./route";
import { Toaster } from "react-hot-toast";

function App() {
  const [state, dispatch] = useReducer(reducer, initialState, initFuc);

  function initFuc(props) {
    return {
      ...props,
      token: localStorage.getItem("token"),
      profile: JSON.parse(localStorage.getItem("profile")),
    };
  }

  return (
    <div className="App h-100">
      <div className="col-md-12 h-100">
        <Context.Provider value={{ state, dispatch }}>
          <Navigation />
          <Toaster />
        </Context.Provider>
      </div>
    </div>
  );
}

export default App;
