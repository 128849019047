import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context";
import {
  verifyOtpServices,
  verifyPhoneNumber,
} from "../../../services/authServices";
import classes from "./SubmitOtp.module.css";

export default function SubmitOtp({
  isLogin,
  setIsCreateAccount,
  createUserPayload,
}) {
  const [otpScreen, setOtpScreen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { dispatch } = useContext(Context);
  const phoneNumber = useRef(null);

  useEffect(() => {
    setOtpScreen(false);
    reset();
  }, [isLogin]);

  const formSubmitHandler = async ({ value }) => {
    try {
      const res = await verifyOtpServices(value, 121231);
      if (res.data.payload) {
        const { access, user } = res.data.payload;
        if (user.is_user_new) {
          setIsCreateAccount(true);
          createUserPayload.current = {
            id: user.id,
            token: access,
          };
        } else {
          dispatch({
            type: "login",
            value: {
              token: access,
              isAdmin: String(user.user_type == "admin"),
              profile: user,
              vendorId: user.user_type !== "admin" ? user.id : null,
            },
          });
          localStorage.setItem("token", access);

          localStorage.setItem("profile", JSON.stringify(user));

          if (user.user_type == "admin") {
            navigate("/vendor_list", { replace: true });
          } else {
            navigate(`/vendor/store/${user.id}`, { replace: true });
          }
        }
        phoneNumber.current = null;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.conent}>
        <h6 className={classes.heading}>
          Enter phone number to register and enjoy benefits.
        </h6>

        <form onSubmit={handleSubmit(formSubmitHandler)}>
          <div className={classes.form_content}>
            <label className={classes.label}>Your Phone Number</label>
            <input
              {...register("value", {
                required: true,
                maxLength: 10,
                minLength: 10,
                pattern: /^[0-9]+$/i,
              })}
              placeholder="Your Phone Number"
              className={`${classes.input} ${
                errors.value && classes.error_content
              }`}
              type="tel"
            />
          </div>
          <div className={classes.btn_container}>
            <button className={classes.btn} type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
