import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import StoreForm from "../components/forms/StoreForm/StoreForm";
import ServicesForm from "../components/forms/servicesForm/ServicesForm";
import PropertiesForm from "../components/forms/properties/PropertiesForm";
import ProfessionForm from "../components/forms/professionForm/ProfessionForm";

// table layout
import Layout from "../layout/Layout";

// table page import
import VendorTable from "../components/table/admin-table/vendor-table/VendorTable";
import StoreTable from "../components/table/admin-table/store-table/StoreTable";
import ServicesTable from "../components/table/admin-table/service-table/ServicesTable";
import RealEstateTable from "../components/table/admin-table/real-estate-table/RealEstateTable";
import ProfessionTable from "../components/table/admin-table/profession-table/ProfessionTable";

import StoreProductForm from "../components/forms/store-product-form/StoreProductForm";
import Login from "../components/screen/auth/Login";
import StoreProductsTable from "../components/table/admin-table/store-products-table/StoreProductsTable";
import EnquiryTable from "../components/table/admin-table/enquiry-table/EnquiryTable";
import { getLocalToken } from "../utility/utilityFunction";

export default function Navigation() {
  const profile_json = localStorage.getItem("profile");
  const profile = JSON.parse(profile_json);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate
            to={
              localStorage.getItem("admin_vendor_id")
                ? `/vendor/store/${localStorage.getItem("admin_vendor_id")}`
                : profile?.user_type == "admin"
                ? "/vendor_list"
                : `/vendor/store/${profile?.id}`
            }
            replace
          />
        }
      />

      <Route path="/login" element={<Login />} />

      <Route path="*" element={<> page not found</>} />

      {/* ----------- admin route ------------------ */}

      <Route
        element={
          profile?.user_type == "admin" ? (
            <Outlet />
          ) : (
            <Navigate to={`/vendor/store/${profile?.id}`} replace />
          )
        }
      >
        <Route
          path="/vendor_list"
          element={
            <PrivateRoute>
              <VendorTable />
            </PrivateRoute>
          }
        />

        <Route
          path="/stores"
          element={
            <PrivateRoute>
              <StoreTable />
            </PrivateRoute>
          }
        />

        <Route
          path="/services"
          element={
            <PrivateRoute>
              <ServicesTable />
            </PrivateRoute>
          }
        />

        <Route
          path="/real_estates"
          element={
            <PrivateRoute>
              <RealEstateTable />
            </PrivateRoute>
          }
        />

        <Route
          path="/professions"
          element={
            <PrivateRoute>
              <ProfessionTable />
            </PrivateRoute>
          }
        />

        <Route
          path="/enquiry"
          element={
            <PrivateRoute>
              <EnquiryTable />
            </PrivateRoute>
          }
        />
      </Route>

      {/* -------------- common create ----------------- */}
      <>
        <Route
          path="/stores/create"
          element={
            <PrivateRoute>
              <StoreForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/services/create"
          element={
            <PrivateRoute>
              <ServicesForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/real_estates/create"
          element={
            <PrivateRoute>
              <PropertiesForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/professions/create"
          element={
            <PrivateRoute>
              <ProfessionForm />
            </PrivateRoute>
          }
        />
      </>

      {/* -------------- vendor route --------------------- */}
      <>
        <Route
          path="/vendor/store/:id"
          element={
            <PrivateRoute>
              <StoreTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/vendor/professions/:id"
          element={
            <PrivateRoute>
              <ProfessionTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/vendor/real_estates/:id"
          element={
            <PrivateRoute>
              <RealEstateTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/vendor/services/:id"
          element={
            <PrivateRoute>
              <ServicesTable />
            </PrivateRoute>
          }
        />
        <Route
          path="/store/store_products/:id"
          element={
            <PrivateRoute>
              <StoreProductsTable />
            </PrivateRoute>
          }
        />

        <Route
          path="/store/store_products/create/:id"
          element={
            <PrivateRoute>
              <StoreProductForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/vendor/enquiry/:id"
          element={
            <PrivateRoute>
              <EnquiryTable />
            </PrivateRoute>
          }
        />
      </>
    </Routes>
  );
}

export function PrivateRoute({ children }) {
  return getLocalToken() ? (
    <>
      <Layout>{children}</Layout>
    </>
  ) : (
    <Navigate to="/login" />
  );
}
