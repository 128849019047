import axios from "axios";
import { getLocalToken } from "../utility/utilityFunction";

const base_url = process.env.REACT_APP_BASE_URL;
// const token = localStorage.getItem("token");

export function getCategoriesList(query) {
  return axios.get(`${base_url}category/?category_type=${query}`, {
    headers: {
      Authorization: "Bearer " + getLocalToken(),
    },
  });
}

export function getTagsLists(type) {
  return axios.get(`${base_url}category/?category_type=${type}`, {
    headers: {
      Authorization: "Bearer " + getLocalToken(),
    },
  });
}

export function vendorApprove(payload) {
  return axios.post(`${base_url}admin-approve`, payload, {
    headers: {
      Authorization: "Bearer " + getLocalToken(),
    },
  });
}

export function vendorActive(payload) {
  return axios.post(`${base_url}admin-active`, payload, {
    headers: {
      Authorization: "Bearer " + getLocalToken(),
    },
  });
}

export function updateData(payload, category_type, id) {
  return axios.put(`${base_url}categoryV2/${category_type}/${id}/`, payload, {
    headers: {
      Authorization: "Bearer " + getLocalToken(),
      "Content-Type": "multipart/form-data",
    },
  });
}

export function deleteEntry(category_type, id) {
  return axios.delete(`${base_url}categoryV2/${category_type}/${id}/`, {
    headers: {
      Authorization: "Bearer " + getLocalToken(),
    },
  });
}

export function verifyType(id, payload) {
  return axios.put(`${base_url}verification/${id}/`, payload, {
    headers: {
      Authorization: "Bearer " + getLocalToken(),
    },
  });
}

export function enquiryStatus(id, payload) {
  return axios.put(`${base_url}enquiry/${id}/`, payload, {
    headers: {
      Authorization: "Bearer " + getLocalToken(),
    },
  });
}
