import React from "react";

const RaliksonMiniIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="25.000000pt"
      height="25.000000pt"
      viewBox="0 0 25.000000 25.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,25.000000) scale(0.100000,-0.100000)"
        fill="#EE382A"
        stroke="none"
      >
        <path
          d="M27 223 c-4 -3 -7 -51 -7 -105 l0 -98 40 0 c22 0 40 2 40 5 0 3 -16
23 -35 44 l-36 39 31 7 c30 7 50 30 50 59 0 35 -62 71 -83 49z"
        />
        <path
          fill="#1B4693"
          d="M185 216 c-25 -11 -55 -50 -55 -71 0 -3 23 -5 50 -5 l50 0 0 45 c0
49 -1 50 -45 31z"
        />
        <path
          fill="#1B4693"
          d="M130 69 c0 -47 1 -50 23 -44 27 8 77 60 77 80 0 11 -12 15 -50 15
l-50 0 0 -51z"
        />
      </g>
    </svg>
  );
};

export default RaliksonMiniIcon;
