import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import classes from "./StoreProductForm.module.css";
import UploadImage from "../form-components/UploadImage/UploadImage";
import {
  createProduct,
  productDetails,
  updateProductDetails,
} from "../../../services/productServices";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getCategoriesList } from "../../../services/utilityApiServices";
import InputDescription from "../form-components/input-description/InputDescription";
import InputTags from "../form-components/input-tags/InputTags";
import { toast } from "react-hot-toast";
import Loader from "../../common/Loader";

const StoreProductForm = () => {
  const category_type = "store_products";
  const [isloading, setIsLoading] = useState(false);

  const [images, setImages] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [inputDescription, setInputDescription] = useState([]);
  const [tags, setTags] = useState([]);

  let { id } = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    getCategoriesList(category_type)
      .then((res) => {
        setCategoryList(res.data);
        searchParams.get("id") != null && getDetails();
      })
      .catch((err) => console.log(err.response));

    return () => {
      setCategoryList([]);
    };
  }, []);

  // * for post api
  function getDetails() {
    productDetails(id, searchParams.get("id"))
      .then((res) => {
        console.log(res.data);
        const {
          name,
          description,
          price,
          category,
          images,
          tags,
          category_description,
        } = res.data;
        setValue("name", name);
        setValue("description", description);
        setValue("price", price);
        setValue("category", category.id);
        setTags(tags.map((e) => e.name));
        setInputDescription(category_description);
        setImages(images.map((e) => e.link));
      })
      .catch((err) => console.log(err));
  }

  const onSubmit = (data) => {
    if (images.length > 0 && tags.length > 0) {
      setIsLoading(true);
      let formData = new FormData();

      Object.keys(data).forEach((el) => {
        formData.append(el, data[el]);
      });

      images.forEach((el) => {
        formData.append("images", el);
      });

      tags.length > 0 &&
        tags.forEach((el) => {
          formData.append("tags", el);
        });

      inputDescription.forEach((el) => {
        formData.append("category_description", el);
      });

      if (searchParams.get("id") != null) {
        updateProductDetails(id, searchParams.get("id"), formData)
          .then((res) => {
            toast.success("form submitted !");
            navigate(-1);
          })
          .catch((err) => {
            console.log(err);
            toast.error("error occurred");
          })
          .finally(() => setIsLoading(false));
      } else {
        createProduct(id, formData)
          .then((res) => {
            toast.success("form submitted !");
            navigate(-1);
          })
          .catch((err) => {
            console.log(err);
            toast.error("error occurred");
          })
          .finally(() => setIsLoading(false));
      }
    }
  };

  return (
    <div className={`${classes.container}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="col-md-12 d-flex h-100">
            <div className="col-md-4 h-100 p-4 ">
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">Product name</label>
                <input
                  placeholder="Enter product name"
                  className="col-md-12"
                  {...register("name", {
                    required: true,
                    // pattern: /^[a-zA-Z0-9+]+$/i,
                  })}
                />
                <span className="text-danger">
                  {errors.name?.type === "required" &&
                    "Product Name is Required "}
                  {errors.name?.type === "pattern" &&
                    "Enter Valid Product Name"}
                </span>
              </div>
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">
                  Product description
                </label>
                <textarea
                  placeholder="Enter product description"
                  className="col-md-12"
                  {...register("description", { required: true })}
                />
                <span className="text-danger">
                  {errors.description?.type === "required" &&
                    "Product Description is Required "}
                </span>
              </div>
            </div>

            <div className="col-md-4 h-100 p-4 ">
              {/* <div
                className={`col-md-12 ${classes.input_container} ${classes.product_description}`}
              >
                <label className="col-md-12 text-start">
                  Product Category description
                </label>
                <textarea
                  placeholder="Product Category description"
                  className="col-md-12"
                  {...register("category_description", { required: true })}
                />
                <span className="text-danger">
                  {errors.category_description?.type === "required" &&
                    "Category Description is Required "}
                </span>
              </div> */}

              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">Cost</label>
                <input
                  placeholder="Enter cost"
                  className="col-md-12"
                  {...register("price", {
                    required: true,
                    pattern: /^[0-9.]+$/i,
                  })}
                />
                <span className="text-danger">
                  {errors.cost?.type === "required" && "Cost is Required "}
                  {errors.cost?.type === "pattern" &&
                    "Only 0-9 digit and decimal(.) are allowed "}
                </span>
              </div>

              <InputDescription
                setInputDescription={setInputDescription}
                inputDescription={inputDescription}
              />

              <div className={`col-md-12 ${classes.input_container}`}>
                <InputTags
                  type={category_type}
                  tags={tags}
                  setTags={setTags}
                  isSubmitted={isSubmitted}
                />
              </div>
            </div>

            <div className="col-md-4 h-100 p-4 ">
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">Product category</label>
                <select
                  className="form-select col-md-12"
                  aria-label="Default select example"
                  {...register("category")}
                >
                  {[
                    { name: "Select product category", id: "" },
                    ...categoryList,
                  ].map((el, index) => (
                    <option key={index} value={el.id}>
                      {el.name}
                    </option>
                  ))}
                </select>
                <span className="text-danger">
                  {errors.category?.type === "required" &&
                    "Product category is Required "}
                </span>
              </div>
              <UploadImage
                name="Product Image"
                images={images}
                setImages={setImages}
                isSubmitted={isSubmitted}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end px-4">
          {isloading ? (
            <Loader />
          ) : (
            <button className="py-2 px-4 rounded bg-primary text-light">
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default StoreProductForm;
