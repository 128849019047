import React, { useState } from "react";
import classes from "./layout.module.css";
import Sidebar from "../components/Sidebar/Sidebar";

import { Link, useLocation, useSearchParams } from "react-router-dom";
import Header from "../components/header/Header";

const Layout = ({ children }) => {
  const location = useLocation();
  const [sideBarMini, setSideBarMini] = useState(true);

  let [query] = useSearchParams();

  const route_path = location.pathname.includes("store_products");

  const toggleSidebarHandler = () => {
    setSideBarMini((p) => !p);
  };

  return (
    <div className="d-flex">
      <div
        className={`col-md-2 position-relative`}
        style={{ width: sideBarMini ? "50px" : "220px" }}
      >
        <Sidebar toggle={toggleSidebarHandler} widthStatus={sideBarMini} />
      </div>
      <div className={` ${classes.dashboard} overflow-auto w-100`}>
        <div className={`${classes.dashboard_container}`}>
          <Header />
          {route_path ? (
            <div className={`${classes.store_name}`}>{query.get('store_name')}</div>
          ) : (
            <div className={`${classes.btn}`}>
              <Link to="/real_estates/create" className={classes.button}>
                Add Property
              </Link>
              <Link to="/stores/create" className={classes.button}>
                Add Store
              </Link>
              <Link to="/services/create" className={classes.button}>
                Add Services
              </Link>
              <Link to="/professions/create" className={classes.button}>
                Add Profession
              </Link>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
