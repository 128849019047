import axios from "axios";
import { getLocalToken } from "../utility/utilityFunction";

const base_url = process.env.REACT_APP_BASE_URL;

export function getProfessionList(id, pageNumber, status, search) {
  return axios.get(
    `${base_url}profession/?vendor_id=${id}&filter_by=${status}&page=${pageNumber}&search=${search}`,
    {
      headers: {
        Authorization: "Bearer " + getLocalToken(),
      },
    }
  );
}

export function createProfession(payload) {
  return axios.post(`${base_url}profession/`, payload, {
    headers: {
      Authorization: "Bearer " + getLocalToken(),
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getProfessionDetails(id) {
  return axios.get(`${base_url}profession/${id}/`, {
    headers: {
      Authorization: "Bearer " + getLocalToken()
    },
  });
}
