import axios from "axios";
import { getLocalToken } from "../utility/utilityFunction";

const base_url = process.env.REACT_APP_BASE_URL;

export function getServicesList(id, pageNumber, status, search) {
  return axios.get(
    `${base_url}service/?vendor_id=${id}&filter_by=${status}&page=${pageNumber}&search=${search}`,
    {
      headers: {
        Authorization: "Bearer " + getLocalToken(),
      },
    }
  );
}

export function createServices(payload) {
  return axios.post(`${base_url}service/`, payload, {
    headers: {
      Authorization: "Bearer " + getLocalToken(),
    },
  });
}

export function getServicesDetail(id) {
  return axios.get(`${base_url}service/${id}/`, {
    headers: {
      Authorization: "Bearer " + getLocalToken(),
    },
  });
}
