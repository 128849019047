import React from "react";

const Approve = ({ fill }) => {
  return (
    <svg
      fill={"none"}
      stroke={fill === undefined ? "currentColor" : "green"}
      strokeWidth={1}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      width={"30px"}
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      ></path>
    </svg>
  );
};

export default Approve;
