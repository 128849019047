import { AgGridReact } from "ag-grid-react";
import React, { useRef, useState, useEffect } from "react";

import { getProductList } from "../../../../services/productServices";
import { Link, useParams } from "react-router-dom";
import classes from "./store_products_table.module.css";
import ActionButton from "../../component/ActionButton";

function StoreProductsTable() {
  const { id } = useParams();

  const [data, setData] = useState();

  const gridRef = useRef();

  useEffect(() => {
    getProductList(id)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err.response));
  }, []);

  const columnDefs = useRef([
    {
      headerName: "ID",
      cellRenderer: (props) => {
        return <>{props.data.id}</>;
      },
    },
    {
      headerName: "Product names",
      field: "name",
    },
    {
      field: "category.category_type",
      headerName: "Product Category",
    },
    {
      headerName: "Product Images",
      cellRenderer: (props) => {
        return (
          <div>
            {props.data.images.slice(0, 2).map((el, index) => (
              <img
                key={index}
                src={el.link}
                style={{ height: "100%", width: 100, marginRight: 5 }}
                alt="image"
              />
            ))}
          </div>
        );
      },
    },

    {
      field: "Action",
      cellRenderer: (props) => {
        return <ActionButton cellData={props} isProductTable={true} />;
      },
    },
  ]).current;

  const defaultColDef = {
    resizable: false,
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: "left" },
  };

  return (
    <>
      <div className={`mt-5 ${classes.store_product_container}`}>
        {/* <VendorSearch /> */}
        {/* <div className="m-4"> */}
        {/* <div>
        <h6>Welcome back !</h6>
        <h2>Ralikson Admin</h2>
        <h4>Today’s insights</h4> 
        <h6>May 17th, 2022</h6>
      </div> */}

        {/* <h2 style={{ marginTop: 20, marginBottom: 20 }}>Simon Dery Store</h2> */}

        <div
          className="row p-0 m-0"
          style={{
            color: "#0D0D0D",
            fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          }}
        >
          {/* Simon Dery Store */}
        </div>

        <Link
          to={`/store/store_products/create/${id}`}
          style={{
            // fontFamily:'Roboto',
            padding: "5px 10px",
            marginTop: "35px",
            borderRadius: "5px",
            color: "#F1F2F6",
            backgroundColor: "#2461FF",
            maxWidth: 200,
            textAlign: "center",
            fontSize: "22px",
            fontWeight: 700,
            textDecorationLine: "none",
          }}
        >
          Add Products
        </Link>

        <p
          style={{
            marginTop: 20,
            marginBottom: 20,
            color: "#757D8A",
            fontFamily: "Inter",
            fontSize: "24px",
            fontFamily: "sans-serif",
            fontWeight: 700,
            lineHeight: "normal",
          }}
        >
          LISTED PRODUCTS
        </p>
        <div
          className={`ag-theme-alpine`}
          style={{
            height: "52vh",
            width: "100%",
            margin: "0px",
            padding: "0px",
            marginTop: "35px",
          }}
        >
          <>
            <AgGridReact
              ref={gridRef}
              rowData={data?.results}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              pagination={true}
              paginationPageSize={10}
            />
          </>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default StoreProductsTable;
