import React, { useState } from "react";
import Approve from "../../../../assets/icons/approved/Approve";
import Reject from "../../../../assets/icons/approved/Reject";
import { vendorApprove } from "../../../../services/utilityApiServices";
import classes from "./Approval.module.css";

const Approval = ({ isApproved, cellData }) => {
  const [hasApproval, sethasApproval] = useState(isApproved);

  const profile_json = localStorage.getItem("profile");
  const profile = JSON.parse(profile_json);

  function approve(boolean) {
    let payload = {
      category_type: cellData?.data?.category?.category_type,
      category_id: cellData.data.id,
      approve: boolean,
    };
    vendorApprove(payload)
      .then((res) => {
        sethasApproval(boolean);
      })
      .catch((err) => console.log(err));
  }

  if (hasApproval === null) {
    return (
      <>
        {localStorage.getItem("admin_vendor_name") == null &&
          profile?.user_type == "admin" && (
            <>
              <div onClick={() => approve(true)} className={classes.icon}>
                <Approve />
              </div>
              <div onClick={() => approve(false)} className={classes.icon}>
                <Reject />
              </div>
            </>
          )}
      </>
    );
  }

  return (
    <>
      {hasApproval == true ? (
        <Approve fill={hasApproval} />
      ) : (
        <Reject fill={hasApproval} />
      )}
    </>
  );
};

export default Approval;
