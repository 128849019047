import React, { useRef } from "react";
import classes from "./input_description.module.css";

function InputDescription({ inputDescription, setInputDescription, name }) {
  const inputRef = useRef(null);

  function deletePoint(index) {
    let temp = inputDescription;
    temp.splice(index, 1);

    setInputDescription([...temp]);
  }

  return (
    <div
      className={`col-md-12 ${classes.input_container} ${classes.input_container_mid}`}
    >
      <label className="col-md-12 text-start">
        {name ? name : "Category description"}
      </label>

      <div>
        {inputDescription.map((el, i) => (
          <div className={`${classes.input_container_select}`} key={i}>
            <div>&bull; {el}</div>
            <span onClick={(e) => deletePoint(i)}>X</span>
          </div>
        ))}
      </div>

      <div className={`${classes.input_container_area}`}
      >
        <textarea className={`col-md-12 ${classes.input_area}`}
          ref={inputRef}
          placeholder="Enter Category description"
          // className="col-md-12"
          // {...register("category_description", {
          //   required: true,
          //   maxLength: 48,
          //   pattern: /^[a-zA-Z0-9,]+$/i,
          // })}
          style={{
            overflow:'hidden'
          }}
          onKeyUp={(event) => {
            if (event.target.value.length > 1 && event.key === "Enter") {
              let value = event.target.value.toString();
              setInputDescription((prev) => [...prev, value]);
              event.target.value = null;
            }
          }}
        />

        <div className={`${classes.attach_svg}`}
          onClick={() => {
            if (inputRef.current.value !== "") {
              let value = inputRef.current.value.toString();
              setInputDescription((prev) => [...prev, value]);
              inputRef.current.value = null;
            }
          }}
        >
          <svg 
            fill="none"
            stroke="currentColor"
            strokeWidth={1.5}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
        </div>
      </div>

      {/* <span className="text-danger">
        {errors.category_description?.type === "required" &&
          "Category Description is Required "}
        {errors.category_description?.type === "maxLength" &&
          "Only 48 characters are allowed "}
        {errors.category_description?.type === "pattern" &&
          "Enter Valid Description "}
      </span> */}
    </div>
  );
}

export default InputDescription;
