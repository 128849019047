import React from "react";
import classes from "./upload_image.module.css";
import { isValidUrl } from "../../../../utility/utilityFunction";

function UploadImage({ images, setImages, name, isSubmitted }) {
  function fileSelector(e) {
    if (e.target.files[0]) {
      setImages((prev) => [...prev, e.target.files[0]]);
    }
  }

  function removeSelectedFiles(fileIndex) {
    let temp = images;
    temp.splice(fileIndex, 1);
    setImages([...temp]);
  }

  return (
    <>
      <div
        className={`col-md-12 ${classes.input_container} ${classes.upload_box}`}
      >
        <label className="col-md-12 text-start mb-1">{name}</label>
        <div className={classes.upload_div}>
          <input
            type="file"
            // style={{ zIndex: 2 }}
            onChange={(e) => fileSelector(e)}
            onBlur={(e) => (e.target.value = null)}
          />
          <div className={classes.property_image}>
            <svg
              fill="none"
              stroke="#5887FF"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              width={"30px"}
              height={"30px"}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
              ></path>
            </svg>
            <div>
              Drag and drop or
              <span className={classes.drag}> choose image </span>
              to upload
            </div>
          </div>
        </div>

        {/* <span className="text-danger">
          {isSubmitted && images.length <= 0 && "Property Image is Required "}
        </span> */}
      </div>
      <div className={`col-md-12 d-flex  ${classes.uploaded_img_container}`}>
        {images.map((item, index) => (
          <div className={`${classes.singleImage}`} key={index}>
            <img
              className="w-12"
              src={item && isValidUrl(item) ? item : URL.createObjectURL(item)}
            />
            <svg
              fill="none"
              stroke="white"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              onClick={() => removeSelectedFiles(index)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </div>
        ))}
      </div>
    </>
  );
}

export default UploadImage;
