import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import classes from "./ProfessionForm.module.css";
import {
  getCategoriesList,
  updateData,
} from "../../../services/utilityApiServices";
import UploadImage from "../form-components/UploadImage/UploadImage";
import UploadPdf from "../form-components/UploadPdf/UploadPdf";

import InputDescription from "../form-components/input-description/InputDescription";
import {
  createProfession,
  getProfessionDetails,
} from "../../../services/professionServices";
import InputTags from "../form-components/input-tags/InputTags";
import { toast } from "react-hot-toast";
import Loader from "../../common/Loader";

const ProfessionForm = () => {
  const category_type = "professions";
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
  } = useForm();

  const [portfolio, setPortfolio] = useState([]);
  const [images, setImages] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [profDescription, setProfDescription] = useState([]);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCategoriesList(category_type)
      .then((res) => {
        setCategoryList(res.data);
        searchParams.get("id") != null && getDetails();
      })
      .catch((err) => console.log(err.response));

    return () => {
      setCategoryList([]);
    };
  }, []);

  // * for post api
  function getDetails() {
    getProfessionDetails(searchParams.get("id"))
      .then((res) => {
        const {
          name,
          contact,
          link,
          city,
          price,
          category,
          service_provider,
          tags,
          description,
          portfolio,
          profession_description,
          images,
        } = res.data;
        setValue("name", name);
        setValue("contact", contact.slice(3));
        setValue("city", city);
        setValue("link", link);
        setValue("price", price);
        setValue("category", category.id);
        setValue("service_provider", service_provider);
        setValue("description", description);
        setTags(tags.map((e) => e.name));
        setPortfolio(portfolio.map((e) => e.link));
        setProfDescription(profession_description);
        setImages(images.map((e) => e.link));
      })
      .catch((err) => console.log(err));
  }

  const onSubmit = (data) => {
    if (tags.length > 0) {
      setIsLoading(true);
      let formData = new FormData();

      Object.keys(data).forEach((el) => {
        formData.append(el, data[el]);
      });

      portfolio.forEach((el) => {
        formData.append("portfolio_files", el);
      });

      profDescription.forEach((el) => {
        formData.append("profession_description", el);
      });

      images.forEach((el) => {
        formData.append("images", el);
      });

      tags.length > 0 &&
        tags.forEach((el) => {
          formData.append("tags", el);
        });

      if (searchParams.get("id") != null) {
        updateData(formData, category_type, searchParams.get("id"))
          .then((res) => {
            toast.success("form submitted !");
            navigate(-1);
          })
          .catch((err) => {
            console.log(err);
            toast.error("error occurred");
          })
          .finally(() => setIsLoading(false));
      } else {
        createProfession(formData)
          .then((res) => {
            toast.success("form submitted !");
            navigate(-1);
          })
          .catch((err) => {
            console.log(err);
            toast.error("error occurred");
          })
          .finally(() => setIsLoading(false));
      }
    }
  };

  return (
    <div className={`${classes.container}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="col-md-12 d-flex h-100">
            <div className="col-md-4 h-100 p-4">
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">Name*</label>
                <input
                  placeholder="Service provider name"
                  className="col-md-12"
                  {...register("name", {
                    required: true,
                  })}
                />
                <span className="text-danger">
                  {errors.name?.type === "required" && "Name is Required "}
                </span>
              </div>
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">Contact details*</label>
                <input
                  type="number"
                  placeholder="Enter phone number"
                  className="col-md-12"
                  {...register("contact", {
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                    pattern: /^[0-9]+$/i,
                  })}
                />
                <span className="text-danger">
                  {errors.contact?.type === "required" &&
                    "Contact Detail is Required "}
                  {errors.contact?.type === "minLength" &&
                    "10 digits Required "}
                  {errors.contact?.type === "maxLength" &&
                    "10 digits Required "}
                  {errors.contact?.type === "pattern" &&
                    "Only Numbers are allowed "}
                </span>
              </div>
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">URL link</label>
                <input
                  placeholder="Enter URL link"
                  className="col-md-12"
                  {...register("link", {
                    pattern:
                      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
                  })}
                />
                <span className="text-danger">
                  {errors.link?.type === "pattern" && "Enter Valid Link "}
                </span>
              </div>
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">City*</label>
                <input
                  placeholder="Enter city name"
                  className="col-md-12"
                  {...register("city", {
                    required: true,
                  })}
                />
                <span className="text-danger">
                  {errors.city?.type === "required" && "City is Required "}
                </span>
              </div>
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">Price*</label>
                <input
                  type="tel"
                  placeholder="Enter price"
                  className="col-md-12"
                  {...register("price", {
                    required: true,
                  })}
                />
                <span className="text-danger">
                  {errors.price?.type === "required" && "Price is Required "}
                </span>
              </div>
            </div>
            <div className="col-md-4 h-100 p-4">
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">
                  Service category*
                </label>
                <select
                  className="form-select col-md-12"
                  {...register("category", { required: true })}
                >
                  {[{ name: "Select category", id: "" }, ...categoryList].map(
                    (el, index) => (
                      <option key={index} value={el.id}>
                        {el.name}
                      </option>
                    )
                  )}
                </select>

                <span className="text-danger">
                  {errors.category?.type === "required" &&
                    "Service Category is Required "}
                </span>
              </div>
              <div className={`col-md-12 ${classes.input_container}`}>
                <InputTags
                  type={category_type}
                  tags={tags}
                  setTags={setTags}
                  isSubmitted={isSubmitted}
                />
              </div>

              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">
                  Profession description*
                </label>
                <textarea
                  placeholder="Profession description"
                  className="col-md-12"
                  {...register("description", { required: true })}
                />
                <span className="text-danger">
                  {errors.description?.type === "required" &&
                    "Profession Description is Required "}
                </span>
              </div>
              <InputDescription
                inputDescription={profDescription}
                setInputDescription={setProfDescription}
                name="Profession Category description"
              />
            </div>
            <div className="col-md-4 h-100 p-4">
              <UploadPdf portfolio={portfolio} setPortfolio={setPortfolio} />

              <UploadImage
                images={images}
                setImages={setImages}
                name="Profession image"
                isSubmitted={isSubmitted}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end px-4">
          {isLoading ? (
            <Loader />
          ) : (
            <button
              className="py-2 px-4 rounded bg-primary text-light"
              type="submit"
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default ProfessionForm;
