import axios from "axios";

const base_url = process.env.REACT_APP_BASE_URL;

export function verifyPhoneNumber(number) {
  return axios.post(`${base_url}auth/login`, {
    phone: number,
  });
}

export function verifyOtpServices(number, otp) {
  return axios.get(`${base_url}auth/otp/verify/${number}?otp=${otp}`);
}

export function updateUser(vendorId, token, formData) {
  return axios.patch(`${base_url}auth/profile/${vendorId}/`, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  });
}

export function checkUserName(name) {
  return axios.get(`${base_url}auth/check-username/${name}`);
}
