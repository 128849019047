import React, { useEffect, useRef, useState } from "react";
import classes from "./enquiry_table.module.css";
import { AgGridReact } from "ag-grid-react";
import Pagination from "../../component/pagination";
import { getEnquiryList } from "../../../../services/enquiryServices";
import EnquirySearch from "../../../enquiry-search/EnquirySearch";
import { enquiryStatus } from "../../../../services/utilityApiServices";

function EnquiryTable() {
  const number = useRef(0);
  const currentCategoryType = useRef(null);

  const [apiResponse, setApiResponse] = useState(null);
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedDataType, setSelectedDataType] = useState(fieldsData[0]);
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "id",
      headerName: "Enq. No.",
    },
    {
      field: "username",
      headerName: "User name",
    },
    {
      field: "contact",
      headerName: "Contact number",
      sortable: true,
    },
    { field: "category", headerName: `property Type` },
    { field: "schedule_time", headerName: "Schedule Time" },
    { field: "location", headerName: "Location" },
    { field: "area", headerName: "Area" },
    { field: "created_at", headerName: "D&T of enquiry" },
    { field: "enquiry_detail", headerName: "Details" },
    {
      field: "status",
      cellRenderer: ({ data }) => {
        return (
          <select
            defaultValue={data?.status}
            onChange={(e) => {
              handleStatus(e.target.value, data);
            }}
          >
            <option value="pending">Pending </option>
            <option value="contacted">Contacted </option>
            <option value="completed">Completed </option>
          </select>
        );
      },
    },
  ]);

  useEffect(() => {
    getEnquiryList(selectedDataType.value, pageNumber, search)
      .then((res) => {
        currentCategoryType.current = selectedDataType.value;
        setApiResponse(res.data);
      })
      .catch((err) => console.log(err));

    return () => {
      setApiResponse(null);
    };
  }, [pageNumber, selectedDataType, search]);

  useEffect(() => {
    setPageNumber(1);
    number.current = 0;
  }, [selectedDataType]);

  const defaultColDef = {
    resizable: false,
    flex: 1,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    cellStyle: { textAlign: "left" },
  };

  function getPageNumber(url) {
    let num = url?.split("page=")[1];
    num = num == undefined ? 1 : num;
    number.current = (parseInt(num) - 1) * 10;
    setPageNumber(num);
  }

  function changeTableHeader(item) {
    setSelectedDataType(item);

    let arr = columnDefs.map((el) => {
      if (el.field == "category") {
        return {
          ...el,
          headerName: `${item.title} Type`,
        };
      } else {
        return el;
      }
    });

    setColumnDefs([...arr]);
  }

  function handleStatus(value, data) {
    const { id } = data;
    const payload = {
      category_type: currentCategoryType.current,
      enquiry_status: value,
    };

    enquiryStatus(id, payload)
      .then((res) => {})
      .catch((err) => console.log(err));
  }

  return (
    <>
      <EnquirySearch
        fieldsData={fieldsData}
        selectedDataType={selectedDataType}
        changeTableHeader={changeTableHeader}
        searchText={setSearch}
      />
      <div className={`ag-theme-alpine ${classes.table}`}>
        <>
          <AgGridReact
            rowData={apiResponse?.results}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
          />
        </>
      </div>
      <Pagination
        data={apiResponse}
        getPageNumber={getPageNumber}
        currentPage={number.current}
      />
    </>
  );
}

export default EnquiryTable;

const fieldsData = [
  {
    title: "Property",
    value: "real_estates",
    icon: (
      <svg
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        className=""
        width={"18px"}
        height={"18px"}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
        ></path>
      </svg>
    ),
  },
  {
    title: "Store",
    value: "stores",
    icon: (
      <svg
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        className=""
        width={"18px"}
        height={"18px"}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
        ></path>
      </svg>
    ),
  },
  {
    title: "Services",
    value: "services",
    icon: (
      <svg
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        width={"18px"}
        height={"18px"}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
        ></path>
      </svg>
    ),
  },
  {
    title: "Profession",
    value: "professions",
    icon: (
      <svg
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        width={"18px"}
        height={"18px"}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
        ></path>
      </svg>
    ),
  },
];
