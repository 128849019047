import React from "react";

const Reject = ({ fill }) => {
  return (
    <svg
      fill={"none"}
      stroke={fill === undefined ? "currentColor" : "red"}
      strokeWidth={1}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      width={"30px"}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

export default Reject;
