import axios from "axios";
import { getLocalToken } from "../utility/utilityFunction";

const base_url = process.env.REACT_APP_BASE_URL;

export function getProductList(storeId) {
  return axios.get(`${base_url}store-product/${storeId}`, {
    headers: {
      Authorization: "Bearer "+ getLocalToken(),
    },
  });
}

export function createProduct(storeId, formData) {
  return axios.post(`${base_url}store-product/${storeId}/`, formData, {
    headers: {
      Authorization: "Bearer "+ getLocalToken(),
      "Content-Type": "multipart/form-data",
    },
  });
}


export function productDetails(storeId, product_id) {
  return axios.get(`${base_url}store-product/${storeId}/${product_id}`, 
  // {
  //   headers: {
  //     Authorization: "Bearer "+ getLocalToken(),
  //     "Content-Type": "multipart/form-data",
  //   },
  // }
  );
}

export function updateProductDetails(storeId, product_id,payload) {
  return axios.patch(`${base_url}store-product/${storeId}/${product_id}/`,payload,
  {
    headers: {
      Authorization: "Bearer "+ getLocalToken(),
      "Content-Type": "multipart/form-data",
    },
  }
  );
}
