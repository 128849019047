import axios from "axios";
import { getLocalToken } from "../utility/utilityFunction";

const base_url = process.env.REACT_APP_BASE_URL;

export function getStoreList(id, pageNumber, status, search) {
  return axios.get(
    `${base_url}store/?page_size=40&vendor_id=${id}&filter_by=${status}&page=${pageNumber}&search=${search}`,
    {
      headers: {
        Authorization: "Bearer " + getLocalToken(),
      },
    }
  );
}

export function createStore(payload) {
  return axios.post(`${base_url}store/`, payload, {
    headers: {
      Authorization: "Bearer " + getLocalToken(),
      "Content-Type": "multipart/form-data",
    },
  });
}

export function vendorList(page_number, status, search) {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}auth/vendors/?page=${page_number}&filter_by=${status}&search=${search} `,
    {
      headers: {
        Authorization: "Bearer " + getLocalToken(),
      },
    }
  );
}

export function getProductDetails(id) {
  return axios.get(`${process.env.REACT_APP_BASE_URL}store/${id}/`, {
    headers: {
      Authorization: "Bearer " + getLocalToken(),
    },
  });
}
