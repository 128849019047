import { createContext } from "react";

export const Context = createContext("");

export const initialState = {
  token: null,
  profile: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "user_type":
      return { ...state, ...action.value, isAdmin: String(false) };

    case "login":
      return {
        ...state,
        ...action.value,
      };
    case "clear":
      return {
        ...state,
        vendorId: null,
        isAdmin: String(state.profile.user_type == "admin"),
        vendorName: null,
      };
    case "logout":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
