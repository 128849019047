import React, { useContext } from "react";
import classes from "./Sidebar.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../context";
import Logo from "../../assets/icons/logo.svg";
import { BsFillCaretLeftSquareFill } from "react-icons/bs";
import RaliksonMiniIcon from "../../assets/icons/RaliksonMiniIcon";

function Sidebar({ toggle, widthStatus }) {
  const history = useLocation();
  const { dispatch } = useContext(Context);

  const navigate = useNavigate();

  const admin_vendor_id = localStorage.getItem("admin_vendor_id");
  const profile_json = localStorage.getItem("profile");
  const profile = JSON.parse(profile_json);
  const vendor_name = localStorage.getItem("admin_vendor_name");

  const id = admin_vendor_id ? admin_vendor_id : profile?.id;

  const side_menu = [
    {
      title: "Vendor",
      path: "/vendor_list",
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          width={"20px"}
          height={"20px"}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
          ></path>
        </svg>
      ),
      is_vendor_route: admin_vendor_id == null && profile.user_type == "admin",
    },
    {
      title: "Store",
      path: "/stores",
      vendorPath: `/vendor/store/${id}`,
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          className=""
          width={"20px"}
          height={"20px"}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
          ></path>
        </svg>
      ),
      is_vendor_route: true,
    },
    {
      title: "Real Estate",
      path: "/real_estates",
      vendorPath: `/vendor/real_estates/${id}`,
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          className=""
          width={"20px"}
          height={"20px"}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
          ></path>
        </svg>
      ),
      is_vendor_route: true,
    },
    {
      title: "Services",
      path: "/services",
      vendorPath: `/vendor/services/${id}`,
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          width={"20px"}
          height={"20px"}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
          ></path>
        </svg>
      ),
      is_vendor_route: true,
    },
    {
      title: "Profession",
      path: "/professions",
      vendorPath: `/vendor/professions/${id}`,
      icon: (
        <svg
          fill="none"
          stroke="currentColor"
          strokeWidth="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          width={"20px"}
          height={"20px"}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
          ></path>
        </svg>
      ),
      is_vendor_route: true,
    },
    {
      title: "Enquiry",
      path: "/enquiry",
      vendorPath: `/vendor/enquiry/${id}`,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M14 7.66669C14.0023 8.5466 13.7967 9.41461 13.4 10.2C12.9296 11.1412 12.2065 11.9328 11.3116 12.4862C10.4168 13.0396 9.3855 13.3329 8.33333 13.3334C7.45342 13.3356 6.58541 13.1301 5.8 12.7334L2 14L3.26667 10.2C2.86995 9.41461 2.66437 8.5466 2.66667 7.66669C2.66707 6.61452 2.96041 5.58325 3.51381 4.68839C4.06722 3.79352 4.85884 3.0704 5.8 2.60002C6.58541 2.20331 7.45342 1.99772 8.33333 2.00002H8.66667C10.0562 2.07668 11.3687 2.66319 12.3528 3.64726C13.3368 4.63132 13.9233 5.94379 14 7.33335V7.66669Z"
            stroke="#757D8A"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      is_vendor_route: true,
    },
  ];

  function logout() {
    dispatch("logout");
    localStorage.clear();
    navigate("/login");
  }

  return (
    <>
      <div
        className={`col-md-12 m-0 ${classes.container} ${
          !widthStatus ? "p-1" : "pt-4 p-2"
        }`}
      >
        <div className="mb-5 d-flex justify-content-between">
          <div
            className={`${classes.logo_container} ${
              !widthStatus ? "p-2" : "p-0"
            }`}
          >
            {!widthStatus ? (
              <img alt="logo" src={Logo} />
            ) : (
              <RaliksonMiniIcon />
            )}
          </div>

          <BsFillCaretLeftSquareFill
            onClick={toggle}
            className={classes.toggle_size}
            style={{
              transform: `${widthStatus ? "rotate(180deg)" : "rotate(0deg)"}`,
            }}
          />
        </div>
        <div className={classes.item_list}>
          {side_menu.map((item, index) => {
            return (
              item.is_vendor_route && (
                <Link
                  key={index}
                  className={`d-flex rounded ${
                    !widthStatus
                      ? classes.single_menu_btn
                      : classes.contracted_sidebar_menu_item
                  }`}
                  to={
                    profile?.user_type == "vendor"
                      ? item.vendorPath
                      : admin_vendor_id == null
                      ? item.path
                      : item.vendorPath
                  }
                  style={{
                    textDecoration: "none",
                    color: "#757d8a",
                    backgroundColor:
                      history.pathname === item.path ? "white" : "",
                  }}
                >
                  <div className={!widthStatus && classes.item_icon}>
                    {item.icon}
                  </div>
                  {!widthStatus && (
                    <div
                      className={`"d-flex align-items-center m-0 p-0 h-100 ${classes.item_title}`}
                    >
                      {item.title}
                    </div>
                  )}
                </Link>
              )
            );
          })}
        </div>
        <div className={`${classes.sidebar_footer}`}>
          {!widthStatus && (
            <div className=" d-flex align-items-center">
              <div className={classes.down_profile}></div>
              <div> {vendor_name ? vendor_name : profile?.first_name}</div>
            </div>
          )}
          <div onClick={logout} className={classes.contracted_sidebar_logout}>
            <svg
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              width={"20px"}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
