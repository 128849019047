import axios from "axios";
import { getLocalToken } from "../utility/utilityFunction";

const base_url = process.env.REACT_APP_BASE_URL;

export function getEnquiryList(category, pageNumber, search) {
  return axios.get(
    `${base_url}enquiry?category_type=${category}&page=${pageNumber}&search=${search}`,
    {
      headers: {
        Authorization: "Bearer " + getLocalToken(),
      },
    }
  );
}
