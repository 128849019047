import React, { useContext, useEffect, useState } from "react";
import classes from "./edit_profile.module.css";
import { useForm } from "react-hook-form";
import { Context } from "../context";
import { updateUser } from "../../services/authServices";

export default function EditProfile({ profile, setIsModal }) {
  const [image, setImage] = useState(null);

  const { dispatch, state } = useContext(Context);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    console.log(profile);
    setValue("first_name", profile?.first_name);
    setValue("bio", profile?.bio);
    setValue("city", profile?.city);
  }, []);

  const onSubmit = (data) => {
    let formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }

    formData.append("profile_image", image);

    let id = localStorage.getItem("admin_vendor_id");

    if (!id) {
      id = profile.id;
    }

    updateUser(id, state?.token, formData)
      .then((res) => {
        const { user } = res.data.payload;
        console.log(res.data);
        dispatch({
          type: "login",
          value: {
            profile: user,
          },
        });

        localStorage.setItem("profile", JSON.stringify(user));
        setIsModal(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form_container}
      >
        <div
    
          style={{
            position: "relative",
            borderRadius: "50%",
            overflow: "hidden",
            height: 120,
            width: 120,
            marginBottom: 20,
            cursor: 'pointer'
          }}
        >
          <div style={{zIndex:9, position: 'absolute', backgroundColor: '#00000077', width: "100%", height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color: "white", margin: 0, fontSize: 12}}>Click to Change</p>
          </div>
          <img
            width="100%"
            height="100%"
            src={image ? URL.createObjectURL(image) : profile?.profile_image}
            alt="Profile image"
          />


          <input
            type="file"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              backgroundColor: "red",
              zIndex:99,
              left: 0,
              cursor: 'pointer'
            }}
            accept="image/png, image/jpeg"
            onChange={(e) => setImage(e.target.files[0])}
          />
        </div>

        <>
          <div className={classes.input_container}>
            <label>Your name</label>
            <input
              {...register("first_name", { required: true })}
              className={classes.input}
              style={{
                borderColor: errors.first_name && "red",
              }}
              placeholder="Enter your name"
            />
          </div>

          <div className={classes.input_container}>
            <label>Bio</label>
            <input
              {...register("bio")}
              className={classes.input}
              placeholder="Enter your Bio"
            />
          </div>

          <div className={classes.input_container}>
            <label>City</label>

            <select
              placeholder="Select your city"
              className={classes.select_city}
              style={{
                borderColor: errors.city && "red",
              }}
              {...register("city", { required: "true" })}
            >
              <option value="" selected disabled hidden>
                select city
              </option>
              {cityList.map((item, index) => (
                <option key={index} className={classes.options_select}>
                  {item}
                </option>
              ))}
            </select>
          </div>

          <button type="submit" className={classes.btn_create_account}>
            Save
          </button>
        </>
      </form>
    </div>
  );
}

const cityList = [
  "Chandigarh",
  "Mohali",
  "Zirakpur",
  "Delhi",
  "Noida",
  "Ghaziabad",
  "Greater Noida",
  "Lucknow",
  "Jaipur",
  "Mumbai",
  "Gurugram-Gurgaon",
  "Faridabad",
  "Pune",
];
