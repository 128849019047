import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import classes from "./StoreForm.module.css";
import { createStore, getProductDetails } from "../../../services/stores";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  getCategoriesList,
  updateData,
} from "../../../services/utilityApiServices";
import InputDescription from "../form-components/input-description/InputDescription";
import UploadImage from "../form-components/UploadImage/UploadImage";
import UploadPdf from "../form-components/UploadPdf/UploadPdf";
import InputTags from "../form-components/input-tags/InputTags";
import { toast } from "react-hot-toast";
import Loader from "../../common/Loader";

const StoreForm = () => {
  const category_type = "stores";
  const [searchParams] = useSearchParams();
  const [categoryList, setCategoryList] = useState([]);

  const [portfolio, setPortfolio] = useState([]);
  const [images, setImages] = useState([]);
  const [inputDescription, setInputDescription] = useState([]);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    getCategoriesList(category_type)
      .then((res) => setCategoryList(res.data))
      .catch((err) => console.log(err.response));

    return () => {
      setCategoryList([]);
    };
  }, []);

  // * for post api

  useEffect(() => {
    if (searchParams.get("id") != null) {
      getProductDetails(searchParams.get("id"))
        .then((res) => {
          const {
            name,
            category,
            locality,
            address,
            description,
            city,
            contact,
            link,
            category_description,
            images,
            portfolio,
            tags,
          } = res.data;
          setValue("name", name);
          setValue("category", category.id);
          setValue("locality", locality);
          setValue("address", address);
          setValue("description", description);
          setValue("contact", contact.slice(3));
          setValue("city", city);
          setValue("link", link);
          setInputDescription(category_description);
          setTags(tags.map((e) => e.name));
          setImages(images.map((e) => e.link));
          setPortfolio(portfolio.map((e) => e.link));
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const onSubmit = (data) => {
    if (tags.length > 0) {
      setIsLoading(true);
      let formData = new FormData();

      Object.keys(data).forEach((el) => {
        formData.append(el, data[el]);
      });

      formData.append("longitude", 9999);
      formData.append("latitude", 9999);

      portfolio.forEach((el) => {
        formData.append("portfolio_files", el);
      });

      images.forEach((el) => {
        formData.append("images", el);
      });

      inputDescription.forEach((el) => {
        formData.append("category_description", el);
      });

      tags.length > 0 &&
        tags.forEach((el) => {
          formData.append("tags", el);
        });

      if (searchParams.get("id") != null) {
        updateData(formData, category_type, searchParams.get("id"))
          .then((res) => {
            toast.success("form submitted !");
            navigate(-1);
          })
          .catch((err) => {
            console.log(err);
            toast.error("error occurred");
          })
          .finally(() => setIsLoading(false));
      } else {
        createStore(formData)
          .then((res) => {
            toast.success("form submitted !");
            navigate(-1);
          })
          .catch((err) => {
            console.log(err);
            toast.error("error occurred");
          })
          .finally(() => setIsLoading(false));
      }
    }
  };

  return (
    <div className={`${classes.container}`}>
      {/* <FormHeader /> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-md-12 d-flex">
          <div className="col-md-4 h-100 p-4">
            <div className={`col-md-12 ${classes.input_container}`}>
              <label className="col-md-12 text-start">Store name*</label>
              <input
                placeholder="Store name"
                {...register("name", {
                  required: true,
                  // maxLength: 48,
                  // pattern: /^[a-zA-Z0-9+]+$/i,
                })}
                className="col-md-12"
              />
              <span className="text-danger">
                {errors.name?.type === "required" && "Store Name is Required "}
                {/* {errors.name?.type === "maxLength" &&
                  "Only 48 letters are allowed "}
                {errors.name?.type === "pattern" &&
                  "Only a-z, 0-9 and + are allowed "} */}
              </span>
            </div>

            <div className={`col-md-12 ${classes.input_container}`}>
              <label className="col-md-12 text-start">Contact details*</label>
              <input
                type="number"
                placeholder="Enter phone number"
                className="col-md-12"
                {...register("contact", {
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                  pattern: /^[+0-9]+$/i,
                })}
              />
              <span className="text-danger">
                {errors.contact?.type === "required" &&
                  "Contact Detail is Required "}
                {errors.contact?.type === "minLength" &&
                  "Only 10 digits Required "}
                {errors.contact?.type === "maxLength" &&
                  "Only 10 digits Required "}
                {errors.contact?.type === "pattern" && "Only Numbers are Valid"}
              </span>
            </div>

            <div className={`col-md-12 ${classes.input_container}`}>
              <label className="col-md-12 text-start">City*</label>
              <input
                placeholder="Enter city name"
                className="col-md-12"
                {...register("city", {
                  required: true,
                  // maxLength: 48,
                  // pattern: /^[a-zA-Z]+$/i,
                })}
              />
              <span className="text-danger">
                {errors.city?.type === "required" && "City is Required "}
                {/* {errors.city?.type === "maxLength" &&
                  "Max 48 characters are allowed "}
                {errors.city?.type === "pattern" && "Only Alphabets are Valid"} */}
              </span>
            </div>

            <div className={`col-md-12 ${classes.input_container}`}>
              <label className="col-md-12 text-start">Locality*</label>
              <input
                placeholder="Enter locality"
                className="col-md-12"
                {...register("locality", {
                  required: true,
                  // maxLength: 48,
                  // pattern: /^[a-zA-Z0-9]+$/i,
                })}
              />
              <span className="text-danger">
                {errors.locality?.type === "required" &&
                  "Locality is Required "}
                {/* {errors.area?.type === "maxLength" &&
                  "Only 48 characters are allowed "}
                {errors.area?.type === "pattern" &&
                  "Only Alphabets and Numbers are allowed "} */}
              </span>
            </div>

            <div className={`col-md-12 ${classes.input_container}`}>
              <label className="col-md-12 text-start">Store Type*</label>
              <select
                className="form-select col-md-12"
                {...register("store_type", { required: true })}
              >
                <option value="">Select type</option>
                <option value="manufacturer">Manufacturer</option>
                <option value="seller">Seller</option>
              </select>
              <span className="text-danger">
                {errors.store_type?.type === "required" &&
                  "Property category is Required "}
              </span>
            </div>

            <div
              className={`col-md-12 ${classes.input_container} ${classes.input_container_mid}`}
            >
              <label className="col-md-12 text-start">Store link</label>
              <input
                placeholder="Enter store link"
                className="col-md-12"
                {...register("link", {
                  // required: true,
                  pattern:
                    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
                })}
              />
              <span className="text-danger">
                {/* {errors.link?.type === "required" && "Store Link is Required "} */}
                {errors.link?.type === "pattern" && "Enter Valid Link "}
              </span>
            </div>

            <div className={`col-md-12 ${classes.input_container}`}>
              <label className="col-md-12 text-start">Store category*</label>
              <select
                className="form-select col-md-12"
                {...register("category", { required: true })}
              >
                {[{ name: "Select category", id: "" }, ...categoryList].map(
                  (item, index) => (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  )
                )}
              </select>
              <span className="text-danger">
                {errors.category?.type === "required" &&
                  "Store Category is Required "}
              </span>
            </div>
          </div>

          <div className="col-md-4 h-100 p-4">
            <div className={`col-md-12 ${classes.input_container}`}>
              <InputTags
                type={category_type}
                tags={tags}
                setTags={setTags}
                isSubmitted={isSubmitted}
              />
            </div>
            <div className={`col-md-12 ${classes.input_container}`}>
              <label className="col-md-12 text-start">Store address*</label>
              <textarea
                placeholder="Enter store address"
                className="col-md-12"
                {...register("address", {
                  required: true,
                  // maxLength: 48,
                  // pattern: /^[a-zA-Z0-9,]+$/i,
                })}
              />
              <span className="text-danger">
                {errors.address?.type === "required" &&
                  "Store Address is Required "}
                {/* {errors.address?.type === "maxLength" &&
                  "Only 48 characters are allowed "}
                {errors.address?.type === "pattern" && "Enter Valid Address "} */}
              </span>
            </div>
            <div
              className={`col-md-12 ${classes.input_container} ${classes.input_container_mid}`}
            >
              <label className="col-md-12 text-start">Store description*</label>
              <textarea
                placeholder="Enter Store description"
                className="col-md-12"
                {...register("description", {
                  required: true,
                  // maxLength: 48,
                  // pattern: /^[a-zA-Z0-9,]+$/i,
                })}
              />
              <span className="text-danger">
                {errors.description?.type === "required" &&
                  "Store Description is Required "}
                {/* {errors.description?.type === "maxLength" &&
                  "Only 48 characters are allowed "}
                {errors.description?.type === "pattern" &&
                  "Enter Valid Address "} */}
              </span>
            </div>
          </div>

          <div className="col-md-4 h-100 p-4">
            <InputDescription
              inputDescription={inputDescription}
              setInputDescription={setInputDescription}
            />
            <UploadPdf portfolio={portfolio} setPortfolio={setPortfolio} />

            <UploadImage
              images={images}
              setImages={setImages}
              name="Import store banner"
              isSubmitted={isSubmitted}
            />
          </div>
        </div>

        <div className={`d-flex justify-content-end px-4 ${classes.button}`}>
          {isLoading ? (
            <Loader />
          ) : (
            <button
              type="submit"
              className="py-2 px-4 rounded bg-primary text-light"
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default StoreForm;
