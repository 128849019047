import Logo from "../../../assets/icons/logo.svg";
import Placeholder from "../../../assets/images/login.png";
import classes from "./AuthLayout.module.css";

const AuthLayout = ({ children }) => {
  return (
    <div
      className={""}
      style={{
        maxHeight: "100vh",
        width: "100vw",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <img
        src={Placeholder}
        alt="image"
        className={""}
        style={{ width: "100%", minHeight: "100vh", objectFit: "cover" }}
      />

      <div
        style={{
          position: "absolute",
          top: "50px",
          left: "50%",
          transform: "translate(-50%)",
          width: "100%",
          textAlign: "center",
        }}
      >
        <h1 className={classes.heading}>
          Welcome to Ralikson business community
        </h1>
      </div>
      <div
        className={""}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className={"bg-white p-5 rounded-3"}>{children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;
