import React, { useRef } from "react";
import classes from "./Vendor_Search.module.css";
import { Link } from "react-router-dom";

function VendorSearch({ filter, status, searchText }) {
  const search = useRef(null);
  return (
    <>
      <div className={`${classes.search_container}`}>
        <div className={`${classes.search_bar}`}>
          <button
            className={` ${classes.search}`}
            onClick={() => searchText(search.current)}
          >
            <i className="bi bi-search"></i>
          </button>
          <input
            className={`${classes.search_input}`}
            type="text"
            onChange={(e) => (search.current = e.target.value)}
          />
          <button
            className={`${classes.search_btn}`}
            onClick={() => searchText(search.current)}
          >
            Search
          </button>
        </div>
        <div className={`${classes.process_options}`}>
          <Link
            href={""}
            onClick={() => filter("All")}
            className={
              status == ""
                ? `${classes.active_link}`
                : `${classes.options_link}`
            }
          >
            All
          </Link>

          <Link
            href={""}
            onClick={() => filter("is_pending")}
            className={
              status == "is_pending"
                ? `${classes.active_link}`
                : `${classes.options_link}`
            }
          >
            Pending
          </Link>
          <Link
            href={""}
            onClick={() => filter("is_approved")}
            className={
              status == "is_approved"
                ? `${classes.active_link}`
                : `${classes.options_link}`
            }
          >
            Confirmed
          </Link>

          <Link
            href={""}
            onClick={() => filter("is_deleted")}
            className={
              status == "is_deleted"
                ? `${classes.active_link}`
                : `${classes.options_link}`
            }
          >
            Deleted
          </Link>
        </div>
      </div>
    </>
  );
}

export default VendorSearch;
