import React, { useRef } from "react";
import classes from "./Enquiry_Search.module.css";

function EnquirySearch({
  fieldsData,
  selectedDataType,
  searchText,
  changeTableHeader,
}) {
  const search = useRef(null);
  return (
    <>
      <div className={`${classes.search_container}`}>
        <div className={`${classes.search_bar}`}>
          <button
            className={` ${classes.search}`}
            onClick={() => searchText(search.current)}
          >
            <i className="bi bi-search"></i>
          </button>
          <input
            className={`${classes.search_input}`}
            type="text"
            onChange={(e) => (search.current = e.target.value)}
          />
          <button
            className={`${classes.search_btn}`}
            onClick={() => searchText(search.current)}
          >
            Search
          </button>
        </div>
        <div className={`${classes.process_options}`}>
          {fieldsData?.map((item, idx) => (
            <div
              key={idx}
              className={`${classes.options_link}
                   ${
                     selectedDataType.value === item.value &&
                     classes.active_link
                   }`}
              onClick={() => changeTableHeader(item)}
            >
              {item.icon}
              {item?.title} Enquiry
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default EnquirySearch;
