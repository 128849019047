import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import classes from "./PropertiesForm.module.css";
import UploadPdf from "../form-components/UploadPdf/UploadPdf";
import UploadImage from "../form-components/UploadImage/UploadImage";
import {
  getCategoriesList,
  updateData,
} from "../../../services/utilityApiServices";
import InputDescription from "../form-components/input-description/InputDescription";
import {
  createRealEstate,
  getRealEstateDetails,
} from "../../../services/realEstateServices";
import InputTags from "../form-components/input-tags/InputTags";
import { toast } from "react-hot-toast";
import Loader from "../../common/Loader";

const PropertiesForm = () => {
  const category_type = "real_estates";

  const [searchParams] = useSearchParams();

  const [portfolio, setPortfolio] = useState([]);
  const [images, setImages] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [inputDescription, setInputDescription] = useState([]);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    trigger,
    setValue,
  } = useForm();

  useEffect(() => {
    getCategoriesList(category_type)
      .then((res) => {
        setCategoryList(res.data);
        searchParams.get("id") != null && getDetails();
      })
      .catch((err) => console.log(err.response));

    return () => {
      setCategoryList([]);
    };
  }, []);

  // * for post api
  function getDetails() {
    getRealEstateDetails(searchParams.get("id"))
      .then((res) => {
        const {
          name,
          contact,
          address,
          locality,
          city,
          tags,
          real_estate_type,
          images,
          link,
          price,
          category,
          category_description,
          area,
          portfolio,
          description,
        } = res.data;
        setValue("name", name);
        setValue("contact", contact.slice(3));
        setValue("address", address);
        setValue("locality", locality);
        setValue("description", description);
        setValue("city", city);
        setValue("category", category.id);
        setTags(tags.map((e) => e.name));
        setValue("real_estate_type", real_estate_type);
        setImages(images.map((e) => e.link));
        setValue("link", link);
        setValue("price", price);
        setInputDescription(category_description);

        setValue("area", area);
        setPortfolio(portfolio.map((e) => e.link));
      })
      .catch((err) => console.log(err));
  }

  const onSubmit = (data) => {
    if (tags.length > 0) {
      setIsLoading(true);
      let formData = new FormData();

      Object.keys(data).forEach((el) => {
        formData.append(el, data[el]);
      });

      portfolio.forEach((el) => {
        formData.append("portfolio_files", el);
      });

      images.forEach((el) => {
        formData.append("images", el);
      });

      inputDescription.forEach((el) => {
        formData.append("category_description", el);
      });

      tags.length > 0 &&
        tags.forEach((el) => {
          formData.append("tags", el);
        });

      if (searchParams.get("id") != null) {
        updateData(formData, category_type, searchParams.get("id"))
          .then((res) => {
            toast.success("form submitted !");
            navigate(-1);
          })
          .catch((err) => {
            console.log(err);
            toast.error("error occurred");
          })
          .finally(() => setIsLoading(false));
      } else {
        createRealEstate(formData)
          .then((res) => {
            toast.success("form submitted !");
            navigate(-1);
          })
          .catch((err) => {
            console.log(err);
            toast.error("error occurred");
          })
          .finally(() => setIsLoading(false));
      }
    }
  };

  return (
    <div className={`${classes.container} overflow-y-auto`}>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-12 d-flex h-100">
            <div className="col-md-4 h-100 p-4">
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">Property name*</label>
                <input
                  placeholder="Property name"
                  className="col-md-12"
                  {...register("name", {
                    required: true,
                  })}
                />
                <span className="text-danger">
                  {errors.name?.type === "required" &&
                    "Property Name is Required "}
                </span>
              </div>

              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">Contact details*</label>
                <input
                  type="number"
                  placeholder="Enter phone number"
                  className="col-md-12"
                  {...register("contact", {
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                    pattern: /^[+0-9]+$/i,
                  })}
                />
                <span className="text-danger">
                  {errors.contact?.type === "required" &&
                    "Contact Detail is Required "}
                  {errors.contact?.type === "minLength" &&
                    "10 digits Required "}
                  {errors.contact?.type === "maxLength" &&
                    "10 digits Required "}
                  {errors.contact?.type === "pattern" &&
                    "Only Numbers are allowed "}
                </span>
              </div>

              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">
                  Property address*
                </label>
                <input
                  placeholder="Property address"
                  className="col-md-12"
                  {...register("address", {
                    required: true,
                  })}
                />
                <span className="text-danger">
                  {errors.address?.type === "required" &&
                    "Property Address is Required "}
                </span>
              </div>
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">Locality*</label>
                <input
                  placeholder="Enter locality"
                  className="col-md-12"
                  {...register("locality", {
                    required: true,
                  })}
                />
                <span className="text-danger">
                  {errors.locality?.type === "required" &&
                    "Locality is Required "}
                </span>
              </div>
              <div
                className={`col-md-12 ${classes.input_container} ${classes.input_container_mid}`}
              >
                <label className="col-md-12 text-start">City*</label>
                <input
                  placeholder="Enter city name"
                  className="col-md-12"
                  {...register("city", {
                    required: true,
                  })}
                />
                <span className="text-danger">
                  {errors.city?.type === "required" && "City is Required "}
                </span>
              </div>
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">Property link</label>
                <input
                  placeholder="Enter property link"
                  className="col-md-12"
                  {...register("link", {
                    pattern:
                      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
                  })}
                />
                <span className="text-danger">
                  {errors.link?.type === "pattern" && "Enter Valid Link "}
                </span>
              </div>

              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">
                  Property area (SqFt)*
                </label>
                <input
                  type="number"
                  placeholder="Enter property area"
                  className="col-md-12"
                  {...register("area", {
                    required: true,
                  })}
                />
                <span className="text-danger">
                  {errors.area?.type === "required" &&
                    "Property Area is Required "}
                </span>
              </div>
            </div>

            <div className="col-md-4 h-100 p-4 ">
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">Price*</label>
                <input
                  placeholder="Enter price"
                  className="col-md-12"
                  type="tel"
                  {...register("price", { required: true })}
                />
                <span className="text-danger">
                  {errors.price?.type === "required" && "Price is Required "}
                </span>
              </div>
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">
                  Property category*
                </label>
                <select
                  className="form-select col-md-12"
                  {...register("category", { required: true })}
                >
                  {[{ name: "Select category", id: "" }, ...categoryList].map(
                    (el, index) => (
                      <option key={index} value={el.id}>
                        {el.name}
                      </option>
                    )
                  )}
                </select>

                <span className="text-danger">
                  {errors.category?.type === "required" &&
                    "Property category is Required "}
                </span>
              </div>
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">Type*</label>
                <select
                  className="form-select col-md-12"
                  {...register("real_estate_type", { required: true })}
                >
                  <option value="">Select type</option>
                  <option value="buy">Buy</option>
                  <option value="rent">Rent</option>
                </select>
                <span className="text-danger">
                  {errors.real_estate_type?.type === "required" &&
                    "Property category is Required "}
                </span>
              </div>
              <div
                className={`col-md-12 ${classes.input_container} ${classes.input_container_mid}`}
              >
                <InputTags
                  type={category_type}
                  tags={tags}
                  setTags={setTags}
                  isSubmitted={isSubmitted}
                />
              </div>
              <div className={`col-md-12 ${classes.input_container}`}>
                <label className="col-md-12 text-start">
                  Property description*
                </label>
                <textarea
                  placeholder="Enter property description"
                  className="col-md-12"
                  {...register("description", { required: true })}
                />
                <span className="text-danger">
                  {errors.description?.type === "required" &&
                    "Property Description is Required "}
                </span>
              </div>
            </div>

            <div className="col-md-4 h-100 p-4">
              <InputDescription
                setInputDescription={setInputDescription}
                inputDescription={inputDescription}
                name="Property category description"
              />

              <UploadPdf
                portfolio={portfolio}
                setPortfolio={setPortfolio}
                register={register}
                errors={errors}
                trigger={trigger}
              />

              <UploadImage
                images={images}
                setImages={setImages}
                name="Property Images"
                isSubmitted={isSubmitted}
              />
            </div>
          </div>
          <div className={`d-flex justify-content-end px-4 ${classes.button}`}>
            {isLoading ? (
              <Loader />
            ) : (
              <button
                className="py-2 px-4 rounded bg-primary text-light"
                type="submit"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default PropertiesForm;
