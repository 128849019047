import React from "react";
import "./Toggle.css";
import Toggle from "react-toggle";

const ToggleSwitch = (statis) => {
  const handleTofuChange = () => {
    //
  };
  return (
    <Toggle
      defaultChecked={true} // this.state.tofuIsReady
      onChange={handleTofuChange}
    />
  );
};

export default ToggleSwitch;
