import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context";
import classes from "./login.module.css";
import { checkUserName, updateUser } from "../../../services/authServices";
import Authlayout from "./Authlayout";
import SubmitOtp from "./SubmitOtp";
import { useForm } from "react-hook-form";

function Login() {
  const navigate = useNavigate();

  const { dispatch } = useContext(Context);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur", reValidateMode: "onBlur" });

  const [login, setLogin] = useState(false);
  const [isCreateAccount, setIsCreateAccount] = useState(false);

  const createUserPayload = useRef(null);

  const onSubmit = (data) => {
    const { id, token } = createUserPayload.current;

    let formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }

    updateUser(id, token, formData)
      .then((res) => {
        const { user } = res.data.payload;
        console.log(res.data);
        dispatch({
          type: "login",
          value: {
            token: token,
            isAdmin: String(user.user_type == "admin"),
            profile: user,
            vendorId: user.user_type !== "admin" ? user.id : null,
          },
        });
        localStorage.setItem("token", token);

        localStorage.setItem("profile", JSON.stringify(user));

        if (user.user_type == "admin") {
          navigate("/vendor_list", { replace: true });
        } else {
          navigate(`/vendor/store/${user.id}`, { replace: true });
        }
      })
      .catch((err) => console.log(err));
  };

  async function checkUsername(name) {
    try {
      await checkUserName(name);
      return true;
    } catch (error) {
      return false;
    }
  }

  return (
    <div>
      <Authlayout>
        {isCreateAccount ? (
          <div className={classes.create_account_form}>
            <h3 className={classes.title}>Create your account </h3>
            {/* <h6 className={classes.sub_title}>
              Re-enter your number, make sure you write the correct phone number
            </h6> */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={classes.input_container}>
                <label>Your Username</label>
                <input
                  {...register("username", {
                    validate: async (username) => await checkUsername(username),
                  })}
                  className={classes.input}
                  style={{
                    borderColor: errors.username && "red",
                  }}
                  placeholder="Enter your name"
                />
                {errors.username && <span>Enter a unique name</span>}
              </div>

              <div className={classes.input_container}>
                <label>Your name</label>
                <input
                  {...register("first_name", { required: true })}
                  className={classes.input}
                  style={{
                    borderColor: errors.first_name && "red",
                  }}
                  placeholder="Enter your name"
                />
              </div>

              <div className={classes.input_container}>
                <label>Bio</label>
                <input
                  {...register("bio")}
                  className={classes.input}
                  placeholder="Enter your Bio"
                />
              </div>

              <div className={classes.input_container}>
                <label>City</label>

                <select
                  placeholder="Select your city"
                  className={classes.select_city}
                  style={{
                    borderColor: errors.city && "red",
                  }}
                  {...register("city", { required: "true" })}
                >
                  <option value="" selected disabled hidden>
                    select city
                  </option>
                  {cityList.map((item, index) => (
                    <option key={index} className={classes.options_select}>
                      {item}
                    </option>
                  ))}
                </select>

                {/* <textarea
                className={classes.textarea}
                placeholder="Enter your Home address"
                rows={10}
                style={{ resize: "none" }}
                ref={address}
              /> */}
              </div>

              <button
                // disabled={isSubmitButton}
                type="submit"
                className={classes.btn_create_account}
              >
                Save
              </button>
            </form>
          </div>
        ) : (
          <>
            <div className={`${classes.upper_button}`}>
              <div className={` ${classes.container}`}>
                <div
                  className={`${login && "text-white"} ${classes.btn} ${
                    login && classes.btn_active
                  } `}
                  onClick={() => setLogin(true)}
                >
                  Login
                </div>
                <div
                  className={`  ${!login && "text-white"} ${classes.btn} ${
                    !login && classes.btn_active
                  }`}
                  onClick={() => setLogin(false)}
                >
                  Sign up
                </div>
              </div>
            </div>
            <SubmitOtp
              isLogin={login}
              setIsCreateAccount={setIsCreateAccount}
              createUserPayload={createUserPayload}
            />
          </>
        )}
      </Authlayout>
    </div>
  );
}

export default Login;

const cityList = [
  "Chandigarh",
  "Mohali",
  "Zirakpur",
  "Delhi",
  "Noida",
  "Ghaziabad",
  "Greater Noida",
  "Lucknow",
  "Jaipur",
  "Mumbai",
  "Gurugram-Gurgaon",
  "Faridabad",
  "Pune",
];
