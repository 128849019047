import React from "react";
import { AiFillCaretRight } from "react-icons/ai";

export default function Pagination({ data, getPageNumber, currentPage }) {
  return (
    <div
      style={{
        display: "flex",
        gap: 20,
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: "10px",
      }}
    >
      <button
        style={{
          // background: "blue",
          fontSize: 20,
          color: "white",
          transform: "rotate(180deg)",
          alignItems: "center",
          display: "flex",
          cursor: "pointer",
        }}
        disabled={!data?.previous}
        onClick={() => getPageNumber(data?.previous)}
      >
        <AiFillCaretRight style={{ color: "black" }} />
      </button>

      {`${1 + currentPage} - ${10 + currentPage}  of ${data?.count} items`}

      <button
        style={{
          // background: "blue",
          fontSize: 20,
          color: "white",
          alignItems: "center",
          display: "flex",
          cursor: "pointer",
        }}
        disabled={!data?.next}
        onClick={() => getPageNumber(data?.next)}
      >
        <AiFillCaretRight style={{ color: "black" }} />
      </button>
    </div>
  );
}
