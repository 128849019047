import axios from "axios";
import { getLocalToken } from "../utility/utilityFunction";

const base_url = process.env.REACT_APP_BASE_URL;

export function getRealEstateList(id, pageNumber, status, search) {
  return axios.get(
    `${base_url}real_estate/?vendor_id=${id}&filter_by=${status}&page=${pageNumber}&search=${search}`,
    {
      headers: {
        Authorization: "Bearer " + getLocalToken(),
      },
    }
  );
}

export function createRealEstate(payload) {
  return axios.post(`${base_url}real_estate/`, payload, {
    headers: {
      Authorization: "Bearer " + getLocalToken(),
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getRealEstateDetails(id) {
  return axios.get(`${base_url}real_estate/${id}/`, {
    headers: {
      Authorization: "Bearer " + getLocalToken(),
    },
  });
}
