import React from "react";
import loader from "../../assets/loader/loader.gif";
import classes from "./Loader.module.css";

const Loader = () => {
  return (
    <div className={classes.loader_container}>
      <img src={loader} alt="loader" />
    </div>
  );
};

export default Loader;
