import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import classes from "./Header.module.css";
import ToggleSwitch from "../common/Toggle";
import { Context } from "../context";
import toast from "react-hot-toast";

import { AiOutlineShareAlt, AiOutlineEdit } from "react-icons/ai";
import ReactModal from "react-modal";
import EditProfile from "../edit-profile/EditProfile";

const Header = () => {
  const { dispatch, state } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();

  const [isModal, setIsModal] = useState(false);
  const route_path = location.pathname.includes("store_products");

  const vendor_name = localStorage.getItem("admin_vendor_name");
  // const profile_json = localStorage.getItem("profile");
  // const profile = JSON.parse(profile_json);
  const profile = state.profile;

  function exitVendor() {
    dispatch({ type: "clear" });
    navigate("/", { replace: true });
    localStorage.removeItem("admin_vendor_id");
    localStorage.removeItem("admin_vendor_name");
  }

  return (
    <div>
      {isModal && (
        <ReactModal
          isOpen={isModal}
          onRequestClose={() => setIsModal(false)}
          className={classes.modal_class}
          overlayClassName={classes.modal_backdrop}
          ariaHideApp={false}
          // portalClassName={classes.modal_class}
        >
          <EditProfile profile={profile} setIsModal={setIsModal} />
        </ReactModal>
      )}
      <div
        className={`d-flex py-3 ${classes.container} justify-content-between`}
      >
        <div className="col-md-8 d-flex p-0 m-0 ">
          <div
            style={{
              borderRadius: "50%",
              backgroundColor: "red",
              overflow: "hidden",
            }}
          >
            <img
              src={
                profile?.profile_image
                  ? profile?.profile_image
                  : "https://media.licdn.com/dms/image/D5603AQEeqariA_a74A/profile-displayphoto-shrink_800_800/0/1692772661422?e=2147483647&v=beta&t=Z_F09bcRIjV0GD0z2FOsMcnvztJRkgv_G9xb2ocVPEU"
              }
              alt="Profile image"
            />
          </div>
          <div className={classes.profile}>
            <div className={classes.greet}>
              {route_path ? "Welcome back" : "Good Evening"}
            </div>
            <div className={classes.name}>
              {vendor_name ? vendor_name : profile?.first_name}

              {profile?.user_type == "vendor" && (
                <div
                  style={{
                    marginLeft: 30,
                    display: "flex",
                    gap: 20,
                    cursor: "pointer",
                  }}
                >
                  <AiOutlineShareAlt
                    size={30}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://ralikson.com/${profile?.username}`
                      );
                      toast.success("url copied");
                    }}
                  />
                  <AiOutlineEdit
                    size={30}
                    onClick={() => setIsModal(true)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className={`col d-flex px-4 justify-content-end align-items-center ${classes.toggle_switch}`}
        >
          {localStorage.getItem("admin_vendor_id") && <ToggleSwitch />}

          <div className={`${classes.svg}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-2 h-2"
              width={"20px"}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
              />
            </svg>
          </div>

          {localStorage.getItem("admin_vendor_id") != null && (
            <div className={`${classes.svg}`} onClick={exitVendor}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M15.1 16.44C14.79 20.04 12.94 21.51 8.89 21.51L8.76 21.51C4.29 21.51 2.5 19.72 2.5 15.25L2.5 8.73001C2.5 4.26001 4.29 2.47001 8.76 2.47001L8.89 2.47001C12.91 2.47001 14.76 3.92001 15.09 7.46001M9 12L20.38 12M18.15 15.35L21.5 12L18.15 8.65001"
                  stroke="#282828"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className={` ${classes.insights}`}>
        <div className={`col-md-8 ${classes.header_footer}`}>
          <div>
            <div>Today’s insights</div>
            <div className={classes.date}>
              {new Date().toLocaleDateString()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
